import { Dictionary } from '../core';
import { ValidationError } from '../services';

export interface ValidatorMixins {
  clearErrorBag(): void;
  feedErrorBag(errors: ValidationError, options?: { key?: string; payload?: Dictionary<any> }): void;
}

export const validatorMixins: ValidatorMixins = {
  clearErrorBag() {
    if (this.$validator) this.$validator.errors.clear();
  },
  feedErrorBag(errors, options = {}) {
    if (errors && errors.errors) {
      Object.keys(errors.errors).forEach(key => {
        errors.errors[key].forEach(error => {
          const code = error.code.toLowerCase();
          if (key === '') {
            const locale = `shared.error.server_validation.${code}`;
            if (this.$te(locale)) {
              this.$toast.error(this.$t(locale));
            } else {
              this.$toast.error(code);
            }
          } else {
            const fieldId = options.key || key;
            this.$validator.errors.add({
              id: fieldId,
              field: fieldId,
              msg: this.$t(`shared.error.server_validation.${code}`, options.payload) as string,
              error_message: error.message,
            });
          }
        });
      });
    }
  },
};
